import { FC, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { useSendTask } from '@core/http/hooks';
import LoginForm from '@modules/auth/components/login/LoginForm';
import { useAuthContext } from '@modules/auth/context';
import { AuthenticateParams } from '@modules/auth/model';
import * as AuthService from '@modules/auth/service';
import Seo from '@shared/modules/seo/Seo';

import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { MediaQuery } from '@mantine/core';
import { LOCK_SCREEN_MAX_WIDTH } from '@root/shared/components/lock/LockScreen';
import { InstallPwa } from './components/login/InstallPwa';

const LoginPage: FC = () => {
  const [modalOpen, setModalOpen] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const { updateProfile } = useAuthContext();

  const [loading, send, error] = useSendTask(AuthService.authenticate, { hideError: true });

  const handleAuthenticate = (params: AuthenticateParams) => {
    pipe(
      () => send(params),
      TE.chainIOK(profile => () => {
        updateProfile(profile);

        const redirectUrl = (location.state as { referrer: Location })?.referrer?.pathname || '/';

        navigate(redirectUrl);
      }),
    )();
  };

  return (
    <>
      <Seo title="Connexion" />

      <LoginForm loading={loading} error={error} onSubmit={handleAuthenticate} />

      <MediaQuery largerThan={LOCK_SCREEN_MAX_WIDTH + 1} styles={{ display: 'none' }}>
        <InstallPwa modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </MediaQuery>
    </>
  );
};

export default LoginPage;
