import { Modal, Stack, Text, Image, MediaQuery } from '@mantine/core';

import chromeScreen from '@root/assets/images/chromeScreen.png';
import safariScreen from '@root/assets/images/safariScreen.png';
import { LOCK_SCREEN_MAX_WIDTH } from '@root/shared/components/lock/LockScreen';
import { browserName } from 'react-device-detect';
import { HiDotsVertical } from 'react-icons/hi';
import { MdIosShare } from 'react-icons/md';
interface InstallPwaProps {
  setModalOpen: (value: boolean) => void;
  modalOpen: boolean;
}

export const InstallPwa = (props: InstallPwaProps) => {
  const { setModalOpen, modalOpen } = props;
  const isChrome = browserName.includes('Chrome');
  const isSafari = browserName.includes('Safari');
  const isPwa = window.matchMedia('(display-mode: standalone)').matches;

  return (
    <MediaQuery largerThan={LOCK_SCREEN_MAX_WIDTH + 1} styles={{ display: 'none' }}>
      <Modal
        opened={modalOpen && !isPwa}
        onClose={() => setModalOpen(false)}
        title={
          <Text color="teal" weight={800} size={20}>
            Installer Hapiix Pro
          </Text>
        }
        centered
        radius={24}
      >
        <Text pb={5} color="teal" weight={800}>
          Comment y accéder facilement ?
        </Text>
        {isSafari && (
          <Stack sx={{ alignItems: 'center' }}>
            <Text>
              Sur Safari, appuyez sur l'icône{' '}
              <MdIosShare
                size={24}
                color="#4693FF"
                style={{
                  backgroundColor: 'black',
                  borderRadius: 4,
                  padding: 4,
                }}
              />{' '}
              et selectionnez "Sur l'écran d'accueil".
            </Text>
            <Image src={safariScreen} sx={{ maxWidth: 299 }} />
          </Stack>
        )}
        {isChrome && (
          <Stack sx={{ alignItems: 'center' }}>
            <Text>
              Sur Chrome, appuyez sur l'icône{' '}
              <HiDotsVertical
                size={24}
                color="white"
                style={{
                  backgroundColor: 'black',
                  borderRadius: 4,
                  padding: 4,
                }}
              />{' '}
              et sélectionnez "Ajouter à l'écran d'accueil".
            </Text>
            <Image src={chromeScreen} sx={{ maxWidth: 299 }} />
          </Stack>
        )}
        {!isSafari && !isChrome && <Text>Ajoutez l'application à votre écran d'accueil.</Text>}
      </Modal>
    </MediaQuery>
  );
};
